import { memo, useCallback, useMemo, useState } from 'react';
import { Button } from '@components/common';
import ChevronDown from '@assets/chevron-down.svg?react';

const ShowMoreButton = () => {
    const [isExpanded, setIsExpanded] = useState(
        () =>
            !!document
                .getElementById('price-table-section')
                ?.classList.contains('price-table-section__expanded'),
    );

    const onToggle = useCallback(() => {
        const table = document.getElementById('price-table-section');

        if (!table) return;

        if (!table.classList.contains('price-table-section__expanded')) {
            table.classList.add('price-table-section__expanded');
            setIsExpanded(true);
        } else {
            table.classList.remove('price-table-section__expanded');
            setIsExpanded(false);
        }
    }, []);

    const ArrowDownStyle = useMemo(
        () => ({
            height: '2rem',
            width: '2rem',
            marginLeft: '1rem',
            transition: ' transform 0.3s',
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transformOrigin: 'center center',
        }),
        [isExpanded],
    );

    return (
        <Button
            className="price-table-section__show-more-button"
            variant="outlined"
            onClick={onToggle}>
            {isExpanded ? 'Zeige weniger' : 'Mehr Anzeigen'}
            <ChevronDown style={ArrowDownStyle} />
        </Button>
    );
};

export default memo(ShowMoreButton);
